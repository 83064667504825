

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import commanModal from "../CommonModals.vue";
import { SalaryStructureData } from "../../../models/sub-domain/salary-head.model";
import { DetailArray } from "../../../models/sub-domain/salary-head.model";

@Component({
  components: {
    commanModal
  }
})
export default class SalaryStructure extends Vue {
  public inProcess = false;
  public salaryStructureData: any = new SalaryStructureData();
  public employeeList = [];
  public salaryHeadList = [];
  public onSalaryHeadList: any = [];
  public earningSalaryHeadList: any = [];
  public deductionSalaryHeadList: any = [];
  public action = "Add";
  public totalSalaryHeadId = null;
  public fixedSalaryHeadId = null;
  public salarySetupCreated = false;
  public source: any = null;
  public totalSalary: any = null;

  /**
   *  Fetch Employee List
   */
  public async fetchEmployeeList() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "administrator/getEmployeeList",
        {
          headers: authHeader()
        }
      );
      this.employeeList = response.data ? response.data : [];
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *  Get Salary Structure
   */
  public async getSalaryStructure() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "payroll/fetchSalaryStructure",
        {
          params: {
            employeeUserId: this.salaryStructureData.employeeUserId
          },
          headers: authHeader()
        }
      );
      this.totalSalary = response.data.payRollData.totalSalary;

      if (
        response.data &&
        response.data.payRollData.totalSalary &&
        !this.source
      ) {
        this.salarySetupCreated = true;
        this.salaryStructureData.totalSalary = null;
        this.$snotify.error("Salary setup already created for this employee");
      } else if (
        response.data.payRollData.totalSalary &&
        this.source == "view-salary-structure"
      ) {
        this.salarySetupCreated = false;
        this.salaryStructureData = response.data.payRollData;
      } else if (
        (!response.data.payRollData.totalSalary &&
          this.source == "view-salary-structure") ||
        (!response.data.payRollData.totalSalary &&
          this.source == "edit-salary-structure")
      ) {
        this.salarySetupCreated = true;
        this.salaryStructureData.totalSalary = null;
        this.$snotify.error("No record found !");
      } else {
        this.salarySetupCreated = false;
        this.salaryStructureData = response.data.payRollData;
      }

      if (this.source == "edit-salary-structure") {
        this.salaryStructureData.salaryType = "re-structure";
      }

      if (this.salaryStructureData && this.salaryStructureData.allSalaryHead) {
        this.salaryStructureData.allSalaryHead.forEach(element => {
          if (element.name == "Total Salary") {
            this.totalSalaryHeadId = element._id;
          }
          if (element.name == "Fixed") {
            this.fixedSalaryHeadId = element._id;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *  Manage conditions of salary slip
   */
  public checkConditionOfSalarySlip(id, typeOfCondition, index) {
    let returnValue = false;
    if (typeOfCondition == "fixedCondition") {
      if (this.salaryStructureData.allSalaryHead[index]._id == id)
        returnValue = false;
      else returnValue = true;
    }

    return returnValue;
  }

  public calculateValues(index, type, value, fieldName) {
    this.salaryStructureData.totalEarning = 0;
    this.salaryStructureData.totalDeduction = 0;
    if (type == "earning") {
      if (
        fieldName == "onSalaryHeadId" ||
        !this.salaryStructureData.earning[index].isApplicable
      ) {
        this.salaryStructureData.earning[index].salaryPercent = null;
        this.salaryStructureData.earning[index].amount = null;
      }
      if (fieldName == "isApplicable") {
        if (!this.salaryStructureData.earning[index].isApplicable) {
          this.salaryStructureData.allSalaryHead.forEach((element, ind) => {
            if (
              element._id ==
              this.salaryStructureData.earning[index].salaryHead._id
            ) {
              this.salaryStructureData.allSalaryHead.splice(ind, 1);
            }
          });
        } else {
          let myObj = {
            name: this.salaryStructureData.earning[index].salaryHead.name,
            type: "Earning",
            _id: this.salaryStructureData.earning[index].salaryHead._id
          };
          this.salaryStructureData.allSalaryHead.push(myObj);
        }
      }
      if (value == this.totalSalaryHeadId) {
        this.salaryStructureData.earning[index].amount =
          (this.salaryStructureData.earning[index].salaryPercent *
            this.salaryStructureData.totalSalary) /
          100;
      } else if (value == this.fixedSalaryHeadId) {
        this.salaryStructureData.earning[index].salaryPercent = null;
        if (this.salaryStructureData.earning[index].amount == 0) {
          this.salaryStructureData.earning[index].amount == null;
        }
      } else {
        this.salaryStructureData.earning.forEach(element => {
          if (value == element.salaryHeadId) {
            if (element.amount > 0) {
              this.salaryStructureData.earning[index].amount =
                (this.salaryStructureData.earning[index].salaryPercent *
                  element.amount) /
                100;
            } else {
              this.salaryStructureData.earning[
                index
              ].onSalaryHeadId = this.totalSalaryHeadId;
              this.salaryStructureData.earning[index].salaryPercent = null;
              this.salaryStructureData.earning[index].amount = 0;
              this.$snotify.error(
                "Selected option's amount should be greater than 0"
              );
            }
          }
        });
      }
    }
    if (type == "deduction") {
      if (
        fieldName == "onSalaryHeadId" ||
        !this.salaryStructureData.deduction[index].isApplicable
      ) {
        this.salaryStructureData.deduction[index].salaryPercent = null;
        this.salaryStructureData.deduction[index].amount = null;
      }
      if (value == this.totalSalaryHeadId) {
        this.salaryStructureData.deduction[index].amount =
          (this.salaryStructureData.deduction[index].salaryPercent *
            this.salaryStructureData.totalSalary) /
          100;
      } else if (value == this.fixedSalaryHeadId) {
        this.salaryStructureData.deduction[index].salaryPercent = null;
        if (this.salaryStructureData.deduction[index].amount == 0) {
          this.salaryStructureData.deduction[index].amount == null;
        }
      } else {
        this.salaryStructureData.earning.forEach(element => {
          if (element.salaryHeadId == value) {
            if (element.amount > 0) {
              this.salaryStructureData.deduction[index].amount =
                (this.salaryStructureData.deduction[index].salaryPercent *
                  element.amount) /
                100;
            } else {
              this.salaryStructureData.deduction[
                index
              ].onSalaryHeadId = this.totalSalaryHeadId;
              this.salaryStructureData.deduction[index].salaryPercent = null;
              this.salaryStructureData.deduction[index].amount = 0;
              this.$snotify.error(
                "Selected option's amount should be greater than 0"
              );
            }
          }
        });
      }
    }
    for (var a = 0; a < this.salaryStructureData.earning.length; a++) {
      this.salaryStructureData.earning.forEach(element => {
        this.salaryStructureData.earning.forEach(element2 => {
          if (element.onSalaryHeadId == element2.salaryHeadId) {
            element.amount = (element2.amount * element.salaryPercent) / 100;
          }
        });
      });
    }
    for (var b = 0; b < this.salaryStructureData.deduction.length; b++) {
      this.salaryStructureData.deduction.forEach(element => {
        this.salaryStructureData.earning.forEach(element2 => {
          if (element.onSalaryHeadId == element2.salaryHeadId) {
            element.amount = (element2.amount * element.salaryPercent) / 100;
          }
        });
      });
    }

    this.salaryStructureData.earning.forEach(element => {
      if (element.amount) {
        this.salaryStructureData.totalEarning =
          parseFloat(element.amount) +
          parseFloat(this.salaryStructureData.totalEarning);
      }
    });
    this.salaryStructureData.deduction.forEach(element => {
      if (element.amount) {
        this.salaryStructureData.totalDeduction =
          parseFloat(element.amount) +
          parseFloat(this.salaryStructureData.totalDeduction);
      }
    });
    this.salaryStructureData.netPayableAmount =
      this.salaryStructureData.totalEarning -
      this.salaryStructureData.totalDeduction;
  }

  /**
   * save salary structure
   */
  public async validateAndSave() {
    try {
      if (
        this.salaryStructureData.totalSalary !=
        this.salaryStructureData.totalEarning
      ) {
        this.$snotify.error(
          "Total earning salary should be equal to Total Salary."
        );
      } else if (
        this.salaryStructureData.salaryType == "increment" &&
        this.totalSalary == this.salaryStructureData.totalSalary
      ) {
        this.$snotify.error("There is no change in salary");
      } else {
        let api =
          this.action == "Add"
            ? "saveSalaryStructure"
            : "updateSalaryStructure";
        let response = await Axios.post(
          BASE_API_URL + "payroll/" + api,
          { payRollData: this.salaryStructureData },
          {
            headers: authHeader()
          }
        );
        if (response.data.code == 200) {
          this.$snotify[response.data.code == 200 ? "success" : "error"](
            response.data.message
          );
          this.$router.push("payroll");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *  Update salary structrue
   */
  public async updateSalaryStructure() {
    this.$router.push({
      path: "add-salary-structure",
      query: { source: "edit-salary-structure" }
    });
    this.source = "edit-salary-structure";
    this.action = "Update";
    this.salaryStructureData.salaryType = "re-structure";
  }

  /**
   *  get monthly salary and manage conditions
   */
  public async getMonthlySalary() {
    this.salaryStructureData.totalSalary =
      this.salaryStructureData.totalSalaryPerYear / 12;

    if (
      this.salaryStructureData.salaryType == "increment" &&
      this.totalSalary > this.salaryStructureData.totalSalary
    ) {
      this.$snotify.error(
        "If you want to decrement the salary , Please choose decrement option"
      );
        this.resetData();
      return;
    } else if (
      this.salaryStructureData.salaryType == "decrement" &&
      this.totalSalary < this.salaryStructureData.totalSalary
    ) {
      this.$snotify.error(
        "If you want to increment the salary , Please choose increment option"
      );
    this.resetData();
      return;
    } else {
     this.resetData();
    }
  }

/**
 *  Reset Data
 */
  public async resetData(){
     this.salaryStructureData.totalEarning = null;
      this.salaryStructureData.totalDeduction = null;
      this.salaryStructureData.netPayableAmount = null;
      this.salaryStructureData.earning.forEach(e => {
        e.salaryPercent = null;
        e.amount = null;
      });
      this.salaryStructureData.deduction.forEach(e => {
        e.salaryPercent = null;
        e.amount = null;
      });
  }

    public async fetchSalaryHeadList() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "payroll/fetchSalaryHeadList",
        {
          headers: authHeader()
        }
      );
      this.salaryHeadList = response.data ? response.data : [];
    } catch (error) {
      console.log(error);
    }
  }
  async mounted() {
    await this.fetchSalaryHeadList();
    await this.fetchEmployeeList();
    this.source = this.$route.query.source;

    if (this.source) {
      this.action = "Update";
    }
  }
}
